<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div v-if="patrolInfo.patrolTypeCd == 'PTC0000001'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 주관부서 -->
          <c-dept
            :plantCd="searchParam.plantCd"
            type="search"
            :label="patrolInfo.patrolTypeCd == 'PTC0000001' ? '점검 주관부서' : '점검 대상부서'"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div>
        <div v-if="patrolInfo.patrolTypeCd == 'PTC0000001'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-vendor
            type="search"
            label="점검 대상업체"
            name="vendorCd"
            v-model="searchParam.vendorCd"
            :selfFlag="false"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 순회기간 -->
          <c-datepicker
            :range="true"
            label="점검기간"
            defaultStart="-1M"
            defaultEnd="0M"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            :editable="false"
            codeGroupCd="PATROL_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="patrolTypeCd"
            label="점검구분"
            v-model="searchParam.patrolTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="patrolCompleteFlag"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.patrolCompleteFlag"
          ></c-select>
        </div>
        <div v-if="patrolInfo.patrolTypeCd == 'PTC0000001'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" v-show="patrolInfo.patrolTypeCd == 'PTC0000002'">
          <c-field
            type="user"
            label="점검자"
            name="sopMapId"
            v-model="searchParam.sopMapId" />
        </div>
      </template>
    </c-search-box>
    <!-- 순회점검 목록 -->
    <c-table
      ref="table"
      :title="patrolInfo.title"
      rowKey="sopEmergencyTrainingId"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="onItemClick" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'patrol',
  props: {
    patrolInfo: {
      type: Object,
      default(){
        return {
          patrolTypeCd: '',
          title: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'patrolDate',
            field: 'patrolDate',
            // 순회일
            label: '점검일',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '점검 주관부서',
            align: 'center',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '점검 대상업체',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'patrolCompleteFlagName',
            field: 'patrolCompleteFlagName',
            // 진행단계
            label: 'LBLPROGRESS',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '점검자',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        deptCd: null,
        vendorCd: null,
        patrolTypeCd: null,
        useFlag: 'Y',
        startYmd: '',
        endYmd: '',
        period: [],
        sopMapId: '',
        regUserId: '',
      },
      statusItems: [
        { code: "N", codeName: "점검중" },
        { code: "Y", codeName: "점검완료" },
      ],
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.patrol.list.url;
      // code setting
      this.searchParam.patrolTypeCd = this.patrolInfo.patrolTypeCd;
      if (this.patrolInfo.patrolTypeCd == 'PTC0000002') {
        this.searchParam.regUserId = this.$store.getters.user.userId;
        this.grid.columns = [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'patrolDate',
            field: 'patrolDate',
            // 순회일
            label: '점검일',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '점검 대상부서',
            align: 'center',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'patrolCompleteFlagName',
            field: 'patrolCompleteFlagName',
            // 진행단계
            label: 'LBLPROGRESS',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '점검자',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
        ]
      }
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period && this.searchParam.period.length === 2) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      console.log(this.$_.clone(this.searchParam))
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    onItemClick() {
      this.linkClick(null);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = (this.patrolInfo.patrolTypeCd == 'PTC0000001' ? '협력업체 점검 상세' : '관리감독자 업무일지 상세'); // 순회점검 상세
      this.popupOptions.param = {
        plantCd: row ? row.plantCd : this.searchParam.plantCd,
        saiPatrolId: row ? row.saiPatrolId : '',
        patrolTypeCd: row ? row.patrolTypeCd : this.patrolInfo.patrolTypeCd
      };
      this.popupOptions.target = () => import(`${'./patrolDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
